body {
	margin: 0;
	font-family: 'Montserrat Regular', 'Helvetica Now', serif ;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

div[role='tooltip'] {
	z-index: 1400;
}

@font-face {
	font-family: 'Helvetica Now';
	src: url('fonts/helvetica_now.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Medium';
	src: url('./fonts/Montserrat/Montserrat-Medium.ttf') format('opentype');
}

@font-face {
	font-family: 'Montserrat Regular';
	src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format('opentype');
}

@font-face {
	font-family: 'Montserrat Bold';
	src: url('./fonts/Montserrat/Montserrat-Bold.ttf') format('opentype');
}

@font-face {
	font-family: 'Montserrat Semi Bold';
	src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('opentype');
}

@font-face {
	font-family: 'Montserrat Italic';
	src: url('./fonts/Montserrat/Montserrat-Italic.ttf') format('opentype');
}

@font-face {
	font-family: 'Archivo Medium';
	src: url('./fonts/Archivo/Archivo-Medium.ttf') format('opentype');
}

@font-face {
	font-family: 'Archivo Regular';
	src: url('./fonts/Archivo/Archivo-Regular.ttf') format('opentype');
}

@font-face {
	font-family: 'Archivo Bold';
	src: url('./fonts/Archivo/Archivo-Bold.ttf') format('opentype');
}

@font-face {
	font-family: 'Archivo Semi Bold';
	src: url('./fonts/Archivo/Archivo-SemiBold.ttf') format('opentype');
}
